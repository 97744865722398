import { configureStore } from '@reduxjs/toolkit';
import homeListRedurcer from './homeDashboard/homeDashBoardSlice';
import homeSportsRedurcer from './sports/sportsSlice';
import meilleursParisReducer from './meilleursParis/meilleursParisSlice';
import meilleursCompReducer from './meilleursCompetition/meilleursCompSlice';
import allSportsReducer from './all-sports/allSportsSLice';
import categoriesReducer from './categories/categoriesSlice';
import tournamentMatchesReducer from './tournament-matches/tournamentMatchesSlice';
import todayMatchesReducer from './today-matches/todayMatchesSlice';
import categoryTournamentsReducer from './category-tournaments/categoryTournamentsSlice';
import betConfigsReducer from './configs/betConfigsSlice';
import systemConfigsReducer from './configs/systemConfigsSlice';
import currencyConfigsReducer from './configs/currencyConfigsSlice';
import placeBetReducer from './place-bet/placeBetsSlice';
import resumeBetsReducer from './place-bet/resumeBetsSlice';
import loginReducer from './login/loginSlice';
import eventMarketsReducer from './Event-markets/eventMarketSlice';
import topMatchesMktsReducer from './meilleursParis/topmatchesMktsSlice';
import allTopMatchesReducer from './meilleursParis/allTopMatchesSlice';
import verifyTicketReducer from './verify-ticket/verifyTicketSlice';
import depositReducer from './deposit/depositSlice';
import cancelTicketReducer from './verify-ticket/cancelTicketSlice';
import payTicketReducer from './verify-ticket/payTicketSlice';
import bonDepositReducer from './deposit/bonDepositSlice';
import getBalanceReducer from './solde/getBalanceSlice';
import voucherProvidersReducer from './deposit/getVoucherProvidersSlice';
import checkWithdrawalReducer from './withdrawal/checkWithdrawalSlice';
import withdrawWithCodeReducer from './withdrawal/withdrawWithCodeSlice';
import historyReducer from './history/historySlice';
import resetPinReducer from './reset-pin/resetPinSlice';
import printTicketReducer from './print/printTicketSlice';
import customerAccountManagementSlice from './customer-account-management/customerAccountManagementSlice';
import navigationHeaderSlice from './nav-header/navHeader';
import clientBonusSlice from './client-bonus/clientBonusSlice';
import customerInfoSlice from './customer-information/customerInfoSlice';

const store = configureStore({
  reducer: {
    homeList: homeListRedurcer,
    sportList: homeSportsRedurcer,
    meilleursParis: meilleursParisReducer,
    meilleursComp: meilleursCompReducer,
    allSports: allSportsReducer,
    categories: categoriesReducer,
    tournamentMatches: tournamentMatchesReducer,
    todayMatches: todayMatchesReducer,
    tournaments: categoryTournamentsReducer,
    betSlip: betConfigsReducer,
    systemConfigs: systemConfigsReducer,
    currencyConfigs: currencyConfigsReducer,
    resumeBets: resumeBetsReducer,
    placeBets: placeBetReducer,
    eventMarkets: eventMarketsReducer,
    topMatchesMkts: topMatchesMktsReducer,
    printTicket: printTicketReducer,
    clientBonus: clientBonusSlice,
    navigationHeader: navigationHeaderSlice,
    login: loginReducer,
    allTopMatches: allTopMatchesReducer,
    ticketData: verifyTicketReducer,
    depositData: depositReducer,
    ticketCanceled: cancelTicketReducer,
    ticketPaid: payTicketReducer,
    bonRechargeData: bonDepositReducer,
    balance: getBalanceReducer,
    voucherProviders: voucherProvidersReducer,
    withdrawalChecked: checkWithdrawalReducer,
    withdrawWithCode: withdrawWithCodeReducer,
    history: historyReducer,
    resetPinData: resetPinReducer,
    customerAccount: customerAccountManagementSlice,
    customerInfo: customerInfoSlice,
  },
});

export default store;
