import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkWithdrawObj, userObj } from 'components/common/requestBody';
import { checkWithdraw } from 'redux/withdrawal/checkWithdrawalSlice';
import { resetCustomerInfo, getCustomerInfo } from 'redux/customer-information/customerInfoSlice';
import { useTranslation } from 'react-i18next';
import translationKeys from 'translations/translationKeys';
import getLanguage from 'components/helpers/getLanguage';
import withdrawal from '../../../../assets/images/mathys/svg/withdrawal.svg';
import customerAccount from '../../../../assets/images/mathys/svg/customer-account.svg';

const Withdrawal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { agentId, agentPhone, agentPwd } = useSelector((store) => store.login);
  const { customer, customerInfoLoading } = useSelector((store) => store.customerInfo);
  const { withdrawn } = useSelector((store) => store.withdrawWithCode);
  const { canNotWithdraw } = useSelector((store) => store.withdrawalChecked);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [disabledValidate, setDisabledValidate] = useState(true);
  const [validateWithdrawal, setValidateWithdrawal] = useState(false);
  const [codeClient, setCodeClient] = useState('');
  const {
    msg19, msg73, msg85, msg84, msg72, msg55, msg49,
  } = translationKeys;

  const handleCodeClient = () => {
    const codeClient = document.getElementById('codeClient').value;
    setCodeClient(codeClient);
    if (customer !== null) {
      dispatch(resetCustomerInfo());
      setDisabledValidate(true);
      setValidateWithdrawal(false);
    }

    setDisabledSubmit(codeClient.length < 1);
  };

  const handleCodeOtp = () => {
    const codeOtp = document.getElementById('codeOtp').value;
    setDisabledValidate(codeOtp.length < 1);
  };

  const loadClientInformation = (e) => {
    e.preventDefault();
    dispatch(getCustomerInfo(codeClient));
  };

  const checkWithdrawal = (e) => {
    e.preventDefault();
    const codeOtp = e.target.elements.codeOtp.value;
    dispatch(checkWithdraw({
      ...checkWithdrawObj,
      agentId,
      agentPwd,
      agentPhone,
      imei: userObj.imei,
      cac: codeClient,
      wcd: codeOtp,
    }));
  };

  const getName = () => {
    if (customer === null) {
      return '';
    }

    const lang = getLanguage();

    if (lang === 'en') {
      return `${customer.firstName} ${customer.lastName}`;
    }

    return `${customer.lastName} ${customer.firstName}`;
  };

  useEffect(() => {
    if (withdrawn) {
      setValidateWithdrawal(false);
      setDisabledSubmit(true);
      setDisabledValidate(true);
      setCodeClient('');
      dispatch(resetCustomerInfo());
      document.getElementById('get-customer-info').reset();
    } else {
      if (canNotWithdraw) {
        document.getElementById('withdraw-with-code-form').reset();
        setDisabledValidate(true);
      }

      if (customer !== null) {
        if (codeClient !== '') {
          setValidateWithdrawal(true);
        } else {
          dispatch(resetCustomerInfo());
          setValidateWithdrawal(false);
        }
      }
    }
  }, [withdrawn, customer, dispatch, canNotWithdraw, codeClient]);

  return (
    <div className="details history">
      <div className="header">
        <img src={withdrawal} alt="" />
        <span>{t(msg19)}</span>
      </div>
      <div className="content steps">
        <form id="get-customer-info" action="" onSubmit={loadClientInformation} className="withdraw-form">
          <div className="content-grid">
            <div className="input-wrap">
              <label htmlFor="codeClient" className="form-label ">
                {t(msg72)}
              </label>
              <input
                type="text"
                className="form-control withdraw-input"
                id="codeClient"
                placeholder={t(msg84)}
                onChange={handleCodeClient}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-secondary"
              disabled={disabledSubmit}
            >
              <span>{t(msg55)}</span>
            </button>
          </div>
        </form>
        {customerInfoLoading && (
          <div className="loader-container">
            <span className="spinner-border large" />
          </div>
        )}
        { validateWithdrawal && !customerInfoLoading && (
          <div className="customer-info-container">
            <img src={customerAccount} alt="profile" className="customer-icon" />
            <div className="customer-info-box">
              <div className="upper-section">
                <p>{getName()}</p>
                <p className="bold">{customer?.accountCode}</p>
              </div>
              <div className="lower-section">
                <div className="customer-info-line">
                  <span>{`${t('age')} :`}</span>
                  <span className="bold">{customer?.age}</span>
                </div>
                <div className="customer-info-line">
                  <span>{`${t('sex')} :`}</span>
                  <span className="bold">{t(customer?.sex)}</span>
                </div>
                <div className="customer-info-line">
                  <span>{`${t('idCard')} :`}</span>
                  <span className="bold">{customer?.idCardNumber}</span>
                </div>
                <div className="customer-info-line">
                  <span>{`${t('phoneNumber')} :`}</span>
                  <span className="bold">{customer?.phoneNumber}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        { validateWithdrawal && !customerInfoLoading && (
        <form id="withdraw-with-code-form" action="" onSubmit={checkWithdrawal} className="withdraw-form">
          <div className="content-grid">
            <div className="input-wrap">
              <label htmlFor="codeOtp" className="form-label ">
                {t(msg73)}
              </label>
              <input
                type="text"
                className="form-control withdraw-input"
                id="codeOtp"
                placeholder={t(msg85)}
                onChange={handleCodeOtp}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-secondary"
              disabled={disabledValidate}
            >
              <span>{t(msg49)}</span>
            </button>
          </div>
        </form>
        )}
      </div>
    </div>
  );
};

export default Withdrawal;
