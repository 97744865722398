import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authParams, getBase64Hash } from 'components/helpers/base64AuthParams';
import getLanguage from 'components/helpers/getLanguage';
import translationKeys from 'translations/translationKeys';
import baseUrls from '../../config';

const apiBaseUrl = baseUrls.BASE_URL_API;
const apiVersion2 = baseUrls.API_VERSION2;
const client = axios.create({
  baseURL: apiBaseUrl,
});

const { msg29 } = translationKeys;

export const getCustomerInfo = createAsyncThunk('customer/info', async (accountCode, thunkAPI) => {
  const lang = getLanguage();
  const api = `/agent-service/agents/code/${accountCode}?lang=${lang}`;
  const signatureParam = `/agent-service/agents/code/${accountCode}lang=${lang}`;
  const url = `/${apiVersion2}${api}`;

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}`)}`,
      },
    };

    const resp = await client.get(url, config);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(msg29);
  }
});

const initialState = {
  customer: null,
  customerInfoLoading: false,
  customerInfoErrorMsg: '',
};

const customerInfoSlice = createSlice({
  name: 'customer information',
  initialState,
  reducers: {
    resetCustomerInfo: (state) => ({ ...state, ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerInfo.pending, (state) => ({
      ...state,
      customerInfoLoading: true,
    }))
      .addCase(getCustomerInfo.fulfilled, (state, { payload }) => {
        let customer = null;
        let errorMsg = '';
        if (Number(payload?.erc) === 1) {
          const data = payload.data[0];
          customer = {
            age: data.intAge,
            firstName: data.strFirstName,
            lastName: data.strLastName,
            sex: data.strSex,
            accountCode: data.strAccountCode,
            phoneNumber: data.strPhone,
            idCardNumber: data.strCustomerIdentification,
          };
        } else {
          errorMsg = payload.data;
        }

        return {
          ...state,
          customer,
          customerInfoLoading: false,
          customerInfoErrorMsg: errorMsg,
        };
      })
      .addCase(getCustomerInfo.rejected, (state) => ({
        ...state,
        customerInfoLoading: false,
        customerInfoErrorMsg: msg29,
      }));
  },
});

export const {
  resetCustomerInfo,
} = customerInfoSlice.actions;

export default customerInfoSlice.reducer;
